body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

.container {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.text {
  font-size: 30px;
  color: #fff;
  text-align: center;
  font-family: 'Pacifico', cursive;
}